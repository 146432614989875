import gql from "graphql-tag";

export const fetchNavigation = gql`
    query {
        navigation(
            is_active: true
        ) {
            main {
                name
                slug

                children {
                    name
                    slug
                }
            }
            
            secondary {
                name
                slug
            }
	    }
    }
`;

export const fetchCoreSettings = gql`
    query {
        coreSettings {
            key
		    value
	    }
    }
`;

export const submitSearch = gql`
    query (
        $value: String
        ) {
            search (
            value: $value
        ) {
            articles {
                name
                lead
                author
                link
                published_at
            }
            
            books {
                name
                lead
                author
                link
            }
            
            events {
                name
                link
                
                category {
                    name
                }
                
                type {
                    name
                }
            }
            
            pages {
                name
                slug
            }
            
            trainings {
                name
                link
                
                category {
                    name
                }
            }
        }
    }
`;

export const submitContactForm = gql`
    mutation submitContactForm (
        $firstname: String
        $lastname: String
        $email: String
        $telephone: String
        $state: String
        $country: String
        $message: String
        $how_to_contact: String
        ) {
        submitContactForm (
            input: {
                firstname: $firstname
                lastname: $lastname
                email: $email
                telephone: $telephone
                state: $state
                country: $country
                message: $message
                how_to_contact: $how_to_contact
            }
        ) {
            id
        }
    }
`

export const submitNewsletterForm = gql`
    mutation submitNewsletterForm (
        $email: String
        $region: String
        $token: String
        ) {
        submitNewsletterForm (
            input: {
                email: $email
                region: $region
                token: $token
            }
        ) {
            id
        }
    }
`

export const fetchEventTypes = gql`
    query {
        types(
            is_active: true
            section: "events"
        ) {
            id
            name
        }
    }
`;

export const fetchSetting = gql`
    query (
        $id: Int!
    ) {
        setting(id: $id) {
            id
            key
            value
        }
    }
`;
