export default [
    { 
        path: '/diplomes-du-cram', 
        redirect: '/diplomes-du-cram/tout-savoir-sur-loffre-aux-diplomes'
    },
    {
        path: '/diplomes-du-cram/tout-savoir-sur-loffre-aux-diplomes',
        name: 'graduates_landing',
        component: () => import('/src/pages/PageGraduates.vue'),
    },
    {
        path: '/diplomes-du-cram/session-en-cours',
        name: 'graduates_calendar',
        component: () => import('/src/pages/PageGraduatesCalendar.vue'),
    },
    {
        path: '/diplomes-du-cram/cours/:category/:slug',
        name: 'graduates_course',
        component: () => import('/src/pages/PageGraduatesCourse.vue'),
    },
    {
        path: '/diplomes-du-cram/catalogue-complet-des-formations',
        name: 'graduates_catalog',
        component: () => import('/src/pages/PageGraduatesCatalog.vue'),
    },
    {
        path: '/diplomes-du-cram/associations-professionnelles',
        name: 'graduates_associations',
        component: () => import('/src/pages/PageGraduatesAssociations.vue'),
    },
    {
        path: '/diplomes-du-cram/sondage',
        name: 'graduates_survey',
        component: () => import('/src/pages/PageGraduatesSurvey.vue'),
    },
    {
        path: '/diplomes-du-cram/graduation',
        name: 'graduates_graduation',
        component: () => import('/src/pages/PageGraduatesGraduation.vue'),
    },
];