import gql from "graphql-tag";

export const fetchCartItemsPrices = gql`
    query (
        $region: String
        $items: [CartItemInput]
        ) {
            fetchCartItemsPrices (
            region: $region
            items: $items
        ) {
            id
            type
            type_name
            name
            starts_at
            slug
            quantity
            price
            total
            tps
            tvq
        }
    }
`;

export const sendConfidentialityApproval = gql`
    query (
        $firstname: String
        $lastname: String
        $telephone: String
        $email: String
        ) {
            sendConfidentialityApproval (
                firstname: $firstname
                lastname: $lastname
                telephone: $telephone
                email: $email
        ) {
            message
            status
        }
    }
`;

export const createRegistrations = gql`
    mutation createRegistrations (
        $items: [CartItemInput]

        $address: String
        $availability: String
        $birth_day: String
        $birth_month: String
        $birth_year: String
        $city: String
        $code: String
        $number_of_persons: Int
        $country: String
        $email: String
        $firstname: String
        $lastname: String
        $meet_remotely: String
        $nearest_subway_station: String
        $objectives: String

        $partner_address: String
        $partner_birth_day: String
        $partner_birth_month: String
        $partner_birth_year: String
        $partner_city: String
        $partner_email: String
        $partner_firstname: String
        $partner_lastname: String
        $partner_postal_code: String
        $partner_telephone: String

        $postal_code: String
        $region: String
        $registration_type: String
        $state: String
        $telephone: String
        $therapist_gender: String
        $therapist_name: String
        $transport: String
        $type: String
        $wants_newsletter: Boolean
        $when_to_call: String
        ) {
        createRegistrations (
            input: {
                items: $items

                address: $address
                availability: $availability
                birth_day: $birth_day
                birth_month: $birth_month
                birth_year: $birth_year
                city: $city
                code: $code
                number_of_persons: $number_of_persons
                country: $country
                email: $email
                firstname: $firstname
                lastname: $lastname
                meet_remotely: $meet_remotely
                nearest_subway_station: $nearest_subway_station
                objectives: $objectives

                partner_address: $partner_address
                partner_birth_day: $partner_birth_day
                partner_birth_month: $partner_birth_month
                partner_birth_year: $partner_birth_year
                partner_city: $partner_city
                partner_email: $partner_email
                partner_firstname: $partner_firstname
                partner_lastname: $partner_lastname
                partner_postal_code: $partner_postal_code
                partner_telephone: $partner_telephone

                postal_code: $postal_code
                region: $region
                registration_type: $registration_type
                state: $state
                telephone: $telephone
                therapist_gender: $therapist_gender
                therapist_name: $therapist_name
                transport: $transport
                type: $type
                wants_newsletter: $wants_newsletter
                when_to_call: $when_to_call
            }
        ) {
            cart_slug
        }
    }
`

export const createGraduationRegistration = gql`
    mutation createGraduationRegistration (
        $region: String
        $code: String
        $firstname: String
        $lastname: String
        $telephone: String
        $email: String
        $number_of_persons: Int
        $type: String   
        $wants_newsletter: Boolean
        ) {
            createGraduationRegistration (
            input: {
                region: $region
                code: $code
                firstname: $firstname
                lastname: $lastname
                telephone: $telephone
                email: $email
                number_of_persons: $number_of_persons
                type: $type
                wants_newsletter: $wants_newsletter
            }
        ) {
            id
            cart_slug
        }
    }
`

export const confirmRegistration = gql`
    mutation confirmRegistration (
        $cart_slug: String
        ) {
        confirmRegistration (
            input: {
                cart_slug: $cart_slug
            }
        ) {
            cart_slug
        }
    }
`
export const createRegistrationToFreeEvent = gql`
    mutation createRegistrationToFreeEvent (
        $date_id: Int
        $firstname: String
        $lastname: String
        $email: String
        $telephone: String
        $region: String
        $reference: String
        $wants_newsletter: Boolean
        ) {
        createRegistrationToFreeEvent (
            input: {
                date_id: $date_id
                firstname: $firstname
                lastname: $lastname
                email: $email
                telephone: $telephone
                reference: $reference
                region: $region
                wants_newsletter: $wants_newsletter
            }
        ) {
            id
        }
    }
`

export const createRegistrationToFreeMeeting = gql`
    mutation createRegistrationToFreeMeeting (
        $date_id: Int
        $firstname: String
        $lastname: String
        $email: String
        $telephone: String
        $state: String
        $country: String
        $region: String
        $reference: String
        $wants_newsletter: Boolean
        ) {
        createRegistrationToFreeMeeting (
            input: {
                date_id: $date_id
                firstname: $firstname
                lastname: $lastname
                email: $email
                telephone: $telephone
                state: $state
                country: $country
                reference: $reference
                region: $region
                wants_newsletter: $wants_newsletter
            }
        ) {
            id
        }
    }
`

export const createCoachingRegistration = gql`
    mutation createCoachingRegistration (
        $city: String
        $postal_code: String
        $address: String
        $company: String
        $department: String
        $position: String
        $firstname: String
        $lastname: String
        $email: String
        $telephone: String
        $state: String
        $country: String
        $objectives: String
        $reference: String
        $wants_newsletter: Boolean
        $when_to_call: String
        ) {
        createCoachingRegistration (
            input: {
                city: $city
                postal_code: $postal_code
                address: $address
                company: $company
                department: $department
                position: $position
                firstname: $firstname
                lastname: $lastname
                email: $email
                telephone: $telephone
                state: $state
                country: $country
                objectives: $objectives
                reference: $reference
                wants_newsletter: $wants_newsletter
                when_to_call: $when_to_call
            }
        ) {
            id
        }
    }
`

export const createPaymentIntent = gql`
    mutation createPaymentIntent (
        $region: String
        $items: [CartItemInput]
        $email: String
        $firstname: String
        $lastname: String
        $telephone: String
        ) {
        createPaymentIntent (
            input: {
                region: $region
                items: $items
                email: $email
                firstname: $firstname
                lastname: $lastname
                telephone: $telephone
            }
        ) {
            client_secret
        }
    }
`

export const confirmPayment = gql`
    mutation confirmPayment (
        $payment_intent_id: String
        $cart_slug: String
        $registration_data: RegistrationInput
        ) {
        confirmPayment (
            input: {
                payment_intent_id: $payment_intent_id
                cart_slug: $cart_slug
                registration_data: $registration_data
            }
        ) {
            message
        }
    }
`