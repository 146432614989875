import { defineStore } from "pinia";
import router from "../routes/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { submitContactForm, submitNewsletterForm } from "@/graphql/core";
import { createRegistrationToFreeMeeting, createRegistrationToFreeEvent, createCoachingRegistration, createRegistrations, confirmRegistration, sendConfidentialityApproval, createGraduationRegistration } from "@/graphql/registrations";

import { useCore } from '@/stores/core'

export const useRegistrations = defineStore('registrations', {    
    state: () => {
        return {
            data: {
                address: null,
                birth_day: "Jour",
                birth_month: "Mois",
                birth_year: "Année",
                city: null,
                // code: "GRADCRAM2025",
                code: "",
                country: null,
                email: "",
                firstname: "",
                lastname: "",
                number_of_persons: 1,
                postal_code: null,
                state: null,
                telephone: "",

                // ANDC help
                availability: '',
                meet_remotely: null,
                nearest_subway_station: null,
                registration_type: null,
                objectives: null,
                therapist_gender: null,
                therapist_name: null,
                transport: null,
                type: "",
                when_to_call: null,

                partner_firstname: '',
                partner_lastname: '',
                partner_address: '',
                partner_postal_code: '',
                partner_telephone: '',
                partner_email: '',
                partner_city: '',
                partner_birth_day: 'Jour',
                partner_birth_month: 'Mois',
                partner_birth_year: 'Année',

                // Coaching
                company: '',
                department: '',
                position: '',

                // Contact
                message: '',
                how_to_contact: [],

                is_completed: false,
                is_consented: false,
                is_absolutely_consented: false,
                wants_newsletter: false,
            },

            errors: {
                address: null,
                birth_day: null,
                birth_month: null,
                birth_year: null,
                city: null,
                code: null,
                invalid_code: false,
                number_of_persons: null,
                email: null,
                firstname: null,
                lastname: null,
                postal_code: null,
                telephone: null,

                // ANDC help
                availability: false,
                meet_remotely: null,
                registration_type: null,
                objectives: null,
                therapist_gender: null,
                transport: null,
                type: null,
                when_to_call: null,

                // Coaching
                company: false,
                department: false,
                position: false,

                show_error_alert: false,
                show_registrations_alert: false,
            },

            andcHelpSteps: {
                num: 1,
                step1: true,
                step2: false,
                step3: false,
                step4: false,
                step5: false,
            },

            trainingBasisRegistrationSteps: {
                num: 1,
                step1: true,
                step2: false,
                step3: false
            },

            referenceOptions: [
                {value: "S/O", label: "Faire un choix"},
                {value: "Baladodiffusion", label: "Baladodiffusion"},
                {value: "Bouche à oreille", label: "Bouche à oreille"},
                {value: "Conférence ou atelier", label: "Conférence ou atelier"},
                {value: "Congrès ou colloque", label: "Congrès ou colloque"},
                {value: "Internet", label: "Internet"},
                {value: "Livres", label: "Livres"},
                {value: "Médias imprimés", label: "Médias imprimés"},
                {value: "Réseaux sociaux", label: "Réseaux sociaux"},
                {value: "Aucun / autre", label: "Aucun / autre"},
            ],

            regionOptions: [
                {value: "north_america", label: "Amérique du Nord"},
                {value: "europe", label: "Europe"},
            ],

            birth_day_options: [
                {value: "Jour", label: "Jour"},
                {value: "01", label: "01"},
                {value: "02", label: "02"},
                {value: "03", label: "03"},
                {value: "04", label: "04"},
                {value: "05", label: "05"},
                {value: "06", label: "06"},
                {value: "07", label: "07"},
                {value: "08", label: "08"},
                {value: "09", label: "09"},
                {value: "10", label: "10"},
                {value: "11", label: "11"},
                {value: "12", label: "12"},
                {value: "13", label: "13"},
                {value: "14", label: "14"},
                {value: "15", label: "15"},
                {value: "16", label: "16"},
                {value: "17", label: "17"},
                {value: "18", label: "18"},
                {value: "19", label: "19"},
                {value: "20", label: "20"},
                {value: "21", label: "21"},
                {value: "22", label: "22"},
                {value: "23", label: "23"},
                {value: "24", label: "24"},
                {value: "25", label: "25"},
                {value: "26", label: "26"},
                {value: "27", label: "27"},
                {value: "28", label: "28"},
                {value: "29", label: "29"},
                {value: "30", label: "30"},
                {value: "31", label: "31"},
            ],

            birth_month_options: [
                {value: "Mois", label: "Mois"},
                {value: "01", label: "01"},
                {value: "02", label: "02"},
                {value: "03", label: "03"},
                {value: "04", label: "04"},
                {value: "05", label: "05"},
                {value: "06", label: "06"},
                {value: "07", label: "07"},
                {value: "08", label: "08"},
                {value: "09", label: "09"},
                {value: "10", label: "10"},
                {value: "11", label: "11"},
                {value: "12", label: "12"},
            ],

            birth_year_options: (() => {
                const currentYear = new Date().getFullYear();

                const years = [];
                years.push({value: "Année", label: "Année"});

                for (let year = currentYear; year >= 1940; year--) {
                    years.push({
                        value: year.toString(),
                        label: year.toString()
                    });
                }
                return years;
            })(),

            approvalSent: false,
        };
    },

    // Actions
    actions: {
        isRequired(attribute) {
            if (this.errors.show_error_alert)
            {
                if (this.data[attribute] === null) {
                    return 'text-red-500';
                }

                if (this.data[attribute] !== undefined && this.data[attribute].length === 0) {
                    return 'text-red-500';
                }
                
                if (attribute === 'birth_date') {
                    if (this.data.birth_day === null || this.data.birth_month === null || this.data.birth_year === null) {
                        return 'text-red-500';
                    }
                }
            }

            return false;
        }, 

        gotoCartPayment(formName) {    
            if (formName == 'andcHelp') {
                localStorage.removeItem('cart');                
                let cart = JSON.parse(localStorage.getItem('cart'));

                const core = useCore();

                if (core.region === 'north_america') {
                    var item = {
                        'id': 1,
                        'slug': 'service-andc-aide-quebec',
                        'type': 'events',
                    };
                }

                if (core.region === 'europe') {
                    var item = {
                        'id': 2,
                        'slug': 'service-andc-aide-europe',
                        'type': 'events',
                    };
                }

                if (cart === null) {
                    cart = [item];
                } else {
                    cart.push(item);
                }

                localStorage.setItem('cart', JSON.stringify(cart));
            }

            if (formName == 'graduation') {
                localStorage.removeItem('cart');                
                let cart = JSON.parse(localStorage.getItem('cart'));

                cart = [
                    {
                        'slug': 'graduation-cram',
                        'number_of_persons': this.data.number_of_persons,
                    }
                ];
                localStorage.setItem('cart', JSON.stringify(cart));
                localStorage.setItem('cart_slug', 'graduation-cram');

                localStorage.setItem('registration', JSON.stringify(this.data));
            }

            window.scrollTo(0, 0);
            router.push('/panier/paiement');
        },

        goToStep(step) {
            this.andcHelpSteps.step1 = false;
            this.andcHelpSteps.step2 = false;
            this.andcHelpSteps.step3 = false;

            window.scrollTo(0, 0);

            if (step == 1) {
                this.andcHelpSteps.num = 1;
                this.andcHelpSteps.step1 = true;
            }

            if (step == 2) {
                this.andcHelpSteps.num = 2;
                this.andcHelpSteps.step2 = true;
            }

            if (step == 3) {
                this.andcHelpSteps.num = 3;
                this.andcHelpSteps.step3 = true;
            }

            if (step == 4) {
                this.andcHelpSteps.num = 4;
                this.andcHelpSteps.step4 = true;
            }

            if (step == 5) {
                this.andcHelpSteps.num = 5;
                this.andcHelpSteps.step5 = true;
            }
        },

        async registerToFreeEvent(date_id) {
            const core = useCore();

            var payloadValidated = true;

            this.errors.firstname = false;
            this.errors.lastname = false;
            this.errors.telephone = false;
            this.errors.email = false;
            
            if (this.data.firstname.length == 0)
            {
                this.errors.firstname = true;
                payloadValidated = false;
            }

            if (this.data.lastname.length == 0)
            {
                this.errors.lastname = true;
                payloadValidated = false;
            }

            if (this.data.telephone.length == 0)
            {
                this.errors.telephone = true;
                payloadValidated = false;
            }

            if (this.data.email.length == 0)
            {
                this.errors.email = true;
                payloadValidated = false;
            }
            
            if (payloadValidated) {
                return new Promise((resolve, reject) => {
                    const { mutate: createRegistrationMutation, onDone, onError } = useMutation (createRegistrationToFreeEvent, () => ({
                        variables: {
                            date_id: date_id,
                            firstname: this.data.firstname,
                            lastname: this.data.lastname,
                            telephone: this.data.telephone,
                            email: this.data.email,
                            reference: this.data.reference,
                            wants_newsletter: this.data.wants_newsletter,
                            region: core.region,
                        },
                    }));
    
                    const mutation = createRegistrationMutation();
    
                    onError(result => {
                        console.log(result);
                    });
    
                    onDone(result => {
                        this.data.is_completed = true;
                    });
                });
            }
        
            return;    
        },

        async registerToFreeMeeting(date_id) {
            const core = useCore();

            var payloadValidated = true;

            this.errors.firstname = false;
            this.errors.lastname = false;
            this.errors.telephone = false;
            this.errors.email = false;
            
            if (this.data.firstname?.length == 0 || this.data.firstname === null)
            {
                this.errors.firstname = true;
                payloadValidated = false;
            }

            if (this.data.lastname?.length == 0 || this.data.lastname === null)
            {
                this.errors.lastname = true;
                payloadValidated = false;
            }

            if (this.data.telephone?.length == 0 || this.data.telephone === null)
            {
                this.errors.telephone = true;
                payloadValidated = false;
            }

            if (this.data.email?.length == 0 || this.data.email === null)
            {
                this.errors.email = true;
                payloadValidated = false;
            }

            if (! payloadValidated) {
                this.errors.show_error_alert = true;
            }
            
            if (payloadValidated) {
                return new Promise((resolve, reject) => {
                    const { mutate: createRegistrationMutation, onDone, onError } = useMutation (createRegistrationToFreeMeeting, () => ({
                        variables: {
                            date_id: date_id,
                            firstname: this.data.firstname,
                            lastname: this.data.lastname,
                            telephone: this.data.telephone,
                            email: this.data.email,
                            state: this.data.state,
                            country: this.data.country,
                            reference: this.data.reference,
                            wants_newsletter: this.data.wants_newsletter,
                            region: core.region,
                        },
                    }));
    
                    const mutation = createRegistrationMutation();
    
                    onError(result => {
                        console.log(result);
                    });
    
                    onDone(result => {
                        this.data.is_completed = true;
                    });
                });
            }
        
            return;    
        },

        async approveConfidentiality(date_id) {
            var payloadValidated = true;

            this.errors.firstname = false;
            this.errors.lastname = false;
            this.errors.telephone = false;
            this.errors.email = false;
            
            if (this.data.firstname.length == 0)
            {
                this.errors.firstname = true;
                payloadValidated = false;
            }

            if (this.data.lastname.length == 0)
            {
                this.errors.lastname = true;
                payloadValidated = false;
            }

            if (this.data.telephone.length == 0)
            {
                this.errors.telephone = true;
                payloadValidated = false;
            }

            if (this.data.email.length == 0)
            {
                this.errors.email = true;
                payloadValidated = false;
            }
            
            if (payloadValidated) {
                return new Promise((resolve, reject) => {
                    const query = useQuery(sendConfidentialityApproval, { 
                        firstname: this.data.firstname,
                        lastname: this.data.lastname,
                        telephone: this.data.telephone,
                        email: this.data.email,
                    });
    
                    query.onError((error) => reject(error));

                    query.onResult((result) => {       
                        this.approvalSent = true;
                        
                        resolve();
                    });
                });
            }  
        },

        setRegistrationBoolean(attribute) {
            this.data[attribute] = !this.data[attribute];
        },
        
        setRegistrationCategory(category) {
            this.data.category = category;
        },

        setRegistrationType(type) {
            this.data.type = type;

            this.goToStep(2);
        },
        
        async submitCoachingForm() {
            var payloadValidated = true;

            this.errors.firstname = false;
            this.errors.lastname = false;
            this.errors.company = false;
            this.errors.department = false;
            this.errors.position = false;
            this.errors.address = false;
            this.errors.city = false;
            this.errors.postal_code = false;
            this.errors.telephone = false;
            this.errors.email = false;
            this.errors.when_to_call = false;
            this.errors.objectives = false;
            
            if (this.data.when_to_call?.length == 0 || this.data.when_to_call === null)
            {
                this.errors.when_to_call = true;
                payloadValidated = false;
            }

            if (this.data.objectives?.length == 0 || this.data.objectives === null)
            {
                this.errors.objectives = true;
                payloadValidated = false;
            }

            if (this.data.address?.length == 0 || this.data.address === null)
            {
                this.errors.address = true;
                payloadValidated = false;
            }

            if (this.data.city?.length == 0 || this.data.city === null)
            {
                this.errors.city = true;
                payloadValidated = false;
            }

            if (this.data.postal_code?.length == 0 || this.data.postal_code === null)
            {
                this.errors.postal_code = true;
                payloadValidated = false;
            }

            if (this.data.company?.length == 0 || this.data.company === null)
            {
                this.errors.company = true;
                payloadValidated = false;
            }

            if (this.data.department?.length == 0 || this.data.department === null)
            {
                this.errors.department = true;
                payloadValidated = false;
            }

            if (this.data.position?.length == 0 || this.data.position === null)
            {
                this.errors.position = true;
                payloadValidated = false;
            }

            if (this.data.firstname?.length == 0 || this.data.firstname === null)
            {
                this.errors.firstname = true;
                payloadValidated = false;
            }

            if (this.data.lastname?.length == 0 || this.data.lastname === null)
            {
                this.errors.lastname = true;
                payloadValidated = false;
            }

            if (this.data.telephone?.length == 0 || this.data.telephone === null)
            {
                this.errors.telephone = true;
                payloadValidated = false;
            }

            if (this.data.email?.length == 0 || this.data.email === null)
            {
                this.errors.email = true;
                payloadValidated = false;
            }

            if (! payloadValidated) {
                this.errors.show_error_alert = true;
            }
            
            if (payloadValidated) {
                return new Promise((resolve, reject) => {
                    const { mutate: createCoachingRegistrationMutation, onDone, onError } = useMutation (createCoachingRegistration, () => ({
                        variables: {
                            firstname: this.data.firstname,
                            lastname: this.data.lastname,
                            company: this.data.company,
                            department: this.data.department,
                            position: this.data.position,
                            address: this.data.address,
                            city: this.data.city,
                            postal_code: this.data.postal_code,
                            state: this.data.state,
                            country: this.data.country,
                            telephone: this.data.telephone,
                            email: this.data.email,
                            when_to_call: this.data.when_to_call,
                            objectives: this.data.objectives,
                            reference: this.data.reference,
                            wants_newsletter: this.data.wants_newsletter,
                        },
                    }));
    
                    const mutation = createCoachingRegistrationMutation();
    
                    onError(result => {
                        console.log(result);
                    });
    
                    onDone(result => {
                        this.data.is_completed = true;

                        window.scrollTo(0, 0);
                    });
                });
            }
        
            return;    
        },

        async submitContactForm() {
            var payloadValidated = true;

            this.errors.firstname = false;
            this.errors.lastname = false;
            this.errors.email = false;
            this.errors.telephone = false;
            this.errors.how_to_contact = false;
            this.errors.message = false;
            
            if (this.data.how_to_contact?.length == 0 || this.data.how_to_contact === null)
            {
                this.errors.how_to_contact = true;
                payloadValidated = false;
            }

            if (this.data.message?.length == 0 || this.data.message === null )
            {
                this.errors.message = true;
                payloadValidated = false;
            }

            if (this.data.firstname?.length == 0 || this.data.firstname === null)
            {
                this.errors.firstname = true;
                payloadValidated = false;
            }

            if (this.data.lastname?.length == 0 || this.data.lastname === null)
            {
                this.errors.lastname = true;
                payloadValidated = false;
            }

            if (this.data.telephone?.length == 0 || this.data.telephone === null)
            {
                this.errors.telephone = true;
                payloadValidated = false;
            }

            if (this.data.email?.length == 0 || this.data.email === null)
            {
                this.errors.email = true;
                payloadValidated = false;
            }

            if (! payloadValidated) {
                this.errors.show_error_alert = true;
            }
            
            if (payloadValidated) {
                return new Promise((resolve, reject) => {
                    const { mutate: submitContactFormMutation, onDone, onError } = useMutation (submitContactForm, () => ({
                        variables: {
                            firstname: this.data.firstname,
                            lastname: this.data.lastname,
                            state: this.data.state,
                            country: this.data.country,
                            telephone: this.data.telephone,
                            email: this.data.email,
                            how_to_contact: this.data.how_to_contact.join(', ').replace('email', 'courriel').replace('telephone', 'téléphone'),
                            message: this.data.message,
                        },
                    }));
    
                    const mutation = submitContactFormMutation();
    
                    onError(result => {
                        console.log(result);
                    });
    
                    onDone(result => {
                        this.data.is_completed = true;

                        window.scrollTo(0, 0);
                    });
                });
            }
        
            return;    
        },

        async submitNewsletterForm() {
            const email = this.data.email;
            const region = this.data.region;

            await grecaptcha.execute('6LfzPikbAAAAAIxw7-VVYug0DAfDiA0nVRgBWp1S', { action: 'newsletter' }).then(function(token) {
                window.token = token;
            });

            return new Promise((resolve, reject) => {
                const { mutate: submitNewsletterFormMutation, onDone, onError } = useMutation (submitNewsletterForm, () => ({
                    variables: {
                        token: window.token,
                        email: email,
                        region: region,
                    },
                }));

                const mutation = submitNewsletterFormMutation();

                onError(result => {
                    console.log(result);
                });

                onDone(result => {
                    this.data.is_subscribed = true;
                    resolve();
                });
            });
        },

        validateEmail() {
            if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.data.email)) {
                this.errors.email = true;
            } else {
                this.errors.email = false;
            }
        },

        validateNumber(attribute, value) {            
            if (! /^[+-]?\d+(\.\d+)?$/.test(this.data[attribute])) {
                this.errors[attribute] = true;
            } else {
                this.errors[attribute] = false;
            }
        },

        validateForm(formName) {
            var is_valid = true;
            var not_required = [];

            const core = useCore();

            if (formName == 'andcHelp')
            {
                not_required = [
                    'code',
                    'number_of_persons',
                    'company',
                    'department',
                    'how_to_contact',
                    'message',
                    'therapist_name', 
                    'nearest_subway_station', 
                    'reference', 
                    'wants_newsletter',
                    'partner_address',
                    'partner_postal_code',
                    'partner_telephone',
                    'partner_email',
                    'partner_city',
                    'position',
                    'country', 
                    'state', 
                ];

                if (core.region === 'europe') {
                    not_required.push(
                        'transport', 
                        'availability', 
                        'therapist_gender', 
                    );
                }
                
                if (this.data.type !== 'Couple' || this.data.type !== 'Enfant') {
                    not_required.push(
                        'partner_firstname',
                        'partner_lastname',
                        'partner_birth_day',
                        'partner_birth_month',
                        'partner_birth_year',
                    );
                }
            }

            if (formName == 'cart')
            {
                not_required = [
                    'code',
                    'number_of_persons',
                    'availability',
                    'how_to_contact',
                    'partner_address',
                    'partner_postal_code',
                    'partner_telephone',
                    'partner_email',
                    'partner_city',
                    'partner_birth_day',
                    'partner_birth_month',
                    'partner_birth_year',
                    'partner_firstname',
                    'partner_lastname',
                    'reference', 
                    'wants_newsletter',
                    'company',
                    'department',
                    'message',
                    'meet_remotely',
                    'nearest_subway_station',
                    'objectives',
                    'position',
                    'registration_type',
                    'therapist_gender',
                    'therapist_name',
                    'transport',
                    'type',
                    'country', 
                    'state', 
                ];
            }

            if (formName == 'graduation')
                {
                    not_required = [
                        'address',
                        'city',
                        'postal_code',
                        'country',
                        'state',
                        'birth_day',
                        'birth_month',
                        'birth_year',
                        'when_to_call',
                        'objectives',
                        'availability',
                        'how_to_contact',
                        'partner_address',
                        'partner_postal_code',
                        'partner_telephone',
                        'partner_email',
                        'partner_city',
                        'partner_birth_day',
                        'partner_birth_month',
                        'partner_birth_year',
                        'partner_firstname',
                        'partner_lastname',
                        'reference', 
                        'wants_newsletter',
                        'company',
                        'department',
                        'message',
                        'meet_remotely',
                        'nearest_subway_station',
                        'objectives',
                        'position',
                        'registration_type',
                        'therapist_gender',
                        'therapist_name',
                        'transport',
                        'type',
                        'country', 
                        'state', 
                    ];
                }

            Object.entries(this.data).forEach(([key, value]) => {
                if (! not_required.some(value => value == key)) {
                    if (value === null || value.length === 0) {
                        is_valid = false;
                        this.errors[key] = true;
                    }
                    else {
                        this.errors[key] = false;
                        
                        if (key == 'email') {
                            if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.data.email)) {
                                is_valid = false;
                                this.errors.email = true;                                
                            } else {
                                is_valid = true;
                                this.errors.email = false;
                            }
                        }
                        
                        if (key == 'birth_day') {
                            if (! /^[+-]?\d+(\.\d+)?$/.test(this.data[key])) {
                                is_valid = false;
                                this.errors[key] = true;
                            } else {
                                is_valid = true;
                                this.errors[key] = false;
                            }
                        }

                        if (key == 'birth_month') {
                            if (! /^[+-]?\d+(\.\d+)?$/.test(this.data[key])) {
                                is_valid = false;
                                this.errors[key] = true;
                            } else {
                                is_valid = true;
                                this.errors[key] = false;
                            }
                        }

                        if (key == 'birth_year') {
                            if (! /^[+-]?\d+(\.\d+)?$/.test(this.data[key])) {
                                is_valid = false;
                                this.errors[key] = true;
                            } else {
                                is_valid = true;
                                this.errors[key] = false;
                            }
                        }
                    }
                }      
            });

            if (this.errors.birth_day || this.errors.birth_month || this.errors.birth_year) {
                is_valid = false;
            }
                 
            if (is_valid === false) {
                this.errors.show_error_alert = true;

                return is_valid;
            } else {
                localStorage.setItem('registration', JSON.stringify(this.data));

                if (formName == 'andcHelp') {
                    localStorage.removeItem('cart');                
                    let cart = JSON.parse(localStorage.getItem('cart'));
    
                    const core = useCore();
    
                    if (core.region === 'north_america') {
                        var item = {
                            'slug': 'service-andc-aide-quebec',
                        };
                    }
    
                    if (core.region === 'europe') {
                        var item = {
                            'slug': 'service-andc-aide-europe',
                        };
                    }
    
                    if (cart === null) {
                        cart = [item];
                    } else {
                        cart.push(item);
                    }
    
                    localStorage.setItem('cart', JSON.stringify(cart));
                }

                if (formName == 'graduation') {
                    if (this.data.number_of_persons == 1) {
                        // May need to setup the cart here when the number of persons is more than 1
                        this.createGraduationRegistration();
                    } else {
                        // Create registrations for each item in the cart
                        this.gotoCartPayment(formName);
                    }
                } else {
                    // Create registrations for each item in the cart
                    this.createRegistrations(formName);
                }
            }
        }, 

        async submitGraduationForm() {
            return new Promise((resolve, reject) => {
                this.validateForm('graduation');
            });
        },

        async createGraduationRegistration() {
            return new Promise((resolve, reject) => {
                const core = useCore();

                const { mutate: createGraduationRegistrationMutation, onDone, onError } = useMutation (createGraduationRegistration, () => ({
                    variables: {
                        region: core.region,
                        code: this.data.code,
                        firstname: this.data.firstname,
                        lastname: this.data.lastname,
                        telephone: this.data.telephone,
                        email: this.data.email,
                        number_of_persons: this.data.number_of_persons,
                        type: this.data.type,
                        wants_newsletter: this.data.wants_newsletter,
                    },
                }));

                const mutation = createGraduationRegistrationMutation();

                onError(result => {
                    console.log(result);
                    if (result.message.includes('invalidCode')) {
                        this.errors.invalid_code = true;
                    } 
                });

                onDone(result => {
                    window.scrollTo(0, 0);
                    router.push('/panier/confirmation');
                });
            });
        },

        async createRegistrations(formName) {
            return new Promise((resolve, reject) => {          
                const core = useCore();
                const { mutate: createRegistrationsMutation, onDone, onError } = useMutation (createRegistrations, () => ({
                    variables: {
                        items: JSON.parse(localStorage.getItem('cart')),

                        firstname: this.data.firstname,
                        lastname: this.data.lastname,
                        address: this.data.address,
                        city: this.data.city,
                        postal_code: this.data.postal_code,
                        state: this.data.state,
                        country: this.data.country,
                        telephone: this.data.telephone,
                        email: this.data.email,
                        birth_day: this.data.birth_day,
                        birth_month: this.data.birth_month,
                        birth_year: this.data.birth_year,
                        when_to_call: this.data.when_to_call,
                        wants_newsletter: this.data.wants_newsletter,
                        region: core.region,
                        reference: this.data.reference,

                        // ANDC help
                        availability: this.data.availability,
                        meet_remotely: this.data.meet_remotely,
                        nearest_subway_station: this.data.nearest_subway_station,
                        registration_type: this.data.registration_type,
                        objectives: this.data.objectives,
                        therapist_gender: this.data.therapist_gender,
                        therapist_name: this.data.therapist_name,
                        transport: this.data.transport,
                        type: this.data.type,
                        when_to_call: this.data.when_to_call,
                        partner_firstname: this.data.partner_firstname,
                        partner_lastname: this.data.partner_lastname,
                        partner_address: this.data.partner_address,
                        partner_postal_code: this.data.partner_postal_code,
                        partner_telephone: this.data.partner_telephone,
                        partner_email: this.data.partner_email,
                        partner_city: this.data.partner_city,
                        partner_birth_day: this.data.partner_birth_day,
                        partner_birth_month: this.data.partner_birth_month,
                        partner_birth_year: this.data.partner_birth_year,
                    },
                }));

                const mutation = createRegistrationsMutation();

                onError(result => {
                    console.log(result); // TODO: Send error to backend
                    this.errors.show_registrations_alert = true;
                });

                onDone(result => {
                    localStorage.setItem('cart_slug', result.data.createRegistrations[0].cart_slug);

                    if (this.data.type == 'Enfant') {
                        this.confirmRegistration();
                    } else {
                        this.gotoCartPayment(formName);
                    }
                });
            });
        },

        async confirmRegistration() {
            return new Promise((resolve, reject) => {
                const { mutate: confirmRegistrationMutation, onDone, onError } = useMutation (confirmRegistration, () => ({
                    variables: {
                        cart_slug: localStorage.getItem('cart_slug'),
                    },
                }));

                const mutation = confirmRegistrationMutation();

                onError(result => {
                    console.log(result); // TODO: Send error to backend
                });

                onDone(result => {
                    window.scrollTo(0, 0);
                    router.push('/panier/confirmation');
                });
            });
        },
    },
});
